import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

export const LazyPPOptInBanner: FunctionComponent = () => {
  const PPOptInBanner = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "personal-productivity-opt-in-banner" */ './PPOptInBanner'
      ),
    { namedImport: 'PPOptInBanner', preload: false },
  );

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        <PPOptInBanner />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};
