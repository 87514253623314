import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@atlaskit/button/new';
import LogOutIcon from '@atlaskit/icon/core/log-out';
import MagicWandIcon from '@atlaskit/icon/core/magic-wand';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import SupportIcon from '@atlaskit/icon/core/support';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { Analytics } from '@trello/atlassian-analytics';
import { useMemberId } from '@trello/authentication';
import { intl } from '@trello/i18n';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { showFlag } from '@trello/nachos/experimental-flags';
import { Popover, usePopover } from '@trello/nachos/popover';
import {
  PopoverMenuButton,
  PopoverMenuLink,
} from '@trello/nachos/popover-menu';

import { useGlobalFeedbackCollector } from 'app/src/components/Header/useGlobalFeedbackCollector';
import { useMemberCohortsFragment } from 'app/src/components/TrelloOnline/MemberCohortsFragment.generated';
import { useAssignPersonalProductivityCohortMutation } from '../AssignPersonalProductivityCohortMutation.generated';

import styles from './PPBetaToolbox.less';

export const PPBetaToolbox: FunctionComponent = () => {
  const memberId = useMemberId();
  const { FeedbackCollector, showFeedbackCollector } =
    useGlobalFeedbackCollector(intl);
  const { triggerRef, toggle, popoverProps } = usePopover();

  const { data: cohortsData } = useMemberCohortsFragment({
    from: { id: memberId },
  });

  const personalProdCohort = useMemo(
    () => cohortsData?.cohorts?.userCohortPersonalProductivity,
    [cohortsData?.cohorts?.userCohortPersonalProductivity],
  );

  const [assignPersonalProductivityCohort] =
    useAssignPersonalProductivityCohortMutation({});

  const onBetaClick = useCallback(() => {
    Analytics.sendClickedButtonEvent({
      buttonName: 'betaPPBetaToolboxButton',
      source: 'ppBetaToolbox',
    });
    toggle();
  }, [toggle]);

  const onPopoverClose = useCallback(() => {
    toggle();
    Analytics.sendClickedButtonEvent({
      buttonName: 'closePPBetaToolboxPopoverButton',
      source: 'ppBetaToolbox',
    });
  }, [toggle]);

  const onFeedbackClick = useCallback(() => {
    showFeedbackCollector();
    toggle();
    Analytics.sendClickedButtonEvent({
      buttonName: 'feedbackPPBetaToolboxPopoverButton',
      source: 'ppBetaToolbox',
    });
  }, [showFeedbackCollector, toggle]);

  const onSupportClick = useCallback(() => {
    Analytics.sendClickedButtonEvent({
      buttonName: 'supportPPBetaToolboxPopoverButton',
      source: 'ppBetaToolbox',
    });
  }, []);

  const onOptToggle = useCallback(async () => {
    const newCohort =
      personalProdCohort === 'opted_out' ? 'opted_in' : 'opted_out';

    Analytics.sendClickedButtonEvent({
      buttonName:
        personalProdCohort === 'opted_out'
          ? 'optInPPBetaToolboxPopoverButton'
          : 'optOutPPBetaToolboxPopoverButton',
      source: 'ppBetaToolbox',
    });

    try {
      const assignCohortRes = await assignPersonalProductivityCohort({
        variables: { assignmentValue: newCohort },
      });
      const cohorts =
        assignCohortRes.data?.assignPersonalProductivityCohort?.cohorts;
      if (
        !cohorts?.find(
          (cohort) =>
            cohort?.name === 'userCohortPersonalProductivity' &&
            cohort?.value === newCohort,
        )
      ) {
        throw new Error(
          `expected cohort name and value missing, cohorts: ${JSON.stringify(cohorts)}`,
        );
      }
      toggle();
    } catch (err) {
      showFlag({
        id: 'ppOptOutBannerError',
        title: 'Error opting out of Personal Productivity.',
        appearance: 'error',
      });
    }
  }, [assignPersonalProductivityCohort, personalProdCohort, toggle]);

  useEffect(() => {
    Analytics.sendViewedComponentEvent({
      componentType: 'inlineDialog',
      componentName: 'ppBetaToolbox',
      source: getScreenFromUrl(),
    });
  }, []);

  return (
    <>
      <Button
        ref={triggerRef}
        onClick={onBetaClick}
        appearance="discovery"
        id="ppBetaToolboxButton"
      >
        <FormattedMessage
          id="templates.pp_launch_and_learn.beta-toolbox.header-button"
          defaultMessage="Beta"
          description="beta button"
        />
        <span className={styles.chevron}>
          <ChevronDownIcon label="" />
        </span>
      </Button>

      <Popover
        {...popoverProps}
        title={intl.formatMessage({
          id: 'templates.pp_launch_and_learn.beta-toolbox.sections.title',
          defaultMessage: 'Trello Beta',
          description: 'dialog title',
        })}
        onHide={onPopoverClose}
      >
        <ol className={styles.menu}>
          <PopoverMenuButton
            iconBefore={<MegaphoneIcon label="" />}
            onClick={onFeedbackClick}
          >
            <span className={styles.title}>
              <FormattedMessage
                id="templates.pp_launch_and_learn.beta-toolbox.sections.feedback"
                defaultMessage="Share your feedback"
                description="feedback button"
              />
            </span>
          </PopoverMenuButton>
          <PopoverMenuLink
            iconBefore={<SupportIcon label="" />}
            href="https://support.atlassian.com/trello/docs/new-trello-support/"
            target="_blank"
            onClick={onSupportClick}
          >
            <span className={styles.title}>
              <FormattedMessage
                id="templates.pp_launch_and_learn.beta-toolbox.sections.support"
                defaultMessage="Get support"
                description="support button"
              />
            </span>
          </PopoverMenuLink>
          <PopoverMenuButton
            iconBefore={
              personalProdCohort === 'opted_out' ? (
                <MagicWandIcon label="" />
              ) : (
                <LogOutIcon label="" />
              )
            }
            onClick={onOptToggle}
          >
            <span className={styles.title}>
              {personalProdCohort === 'opted_out' ? (
                <FormattedMessage
                  id="templates.pp_launch_and_learn.beta-toolbox.sections.enter"
                  defaultMessage="Try the Beta experience"
                  description="enter button"
                />
              ) : (
                <FormattedMessage
                  id="templates.pp_launch_and_learn.beta-toolbox.sections.exit"
                  defaultMessage="Exit Beta"
                  description="exit button"
                />
              )}
            </span>
          </PopoverMenuButton>
        </ol>
      </Popover>

      <FeedbackCollector />
    </>
  );
};
