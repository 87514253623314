// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("resources/images/TinyTacoTalking.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tRc78MH7h7eEaW {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;
  padding: 6px 12px;
  background-color: var(--ds-background-accent-blue-subtle, #1868db);
}
.u83SxONudILTXX {
  display: flex;
  margin-right: auto;
}
._d5VkNpiqsvcw1 {
  width: 33px;
  height: 41px;
  margin: 0 12px -10px 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
}
.u0MQkZwigAb_cp {
  position: relative;
  height: 100%;
  margin-right: auto;
  padding: 6px 8px;
  border-radius: 3px;
  background-color: var(--ds-background-information, #e9f2ff);
}
.u0MQkZwigAb_cp .XZoNmzSh_1lpdU {
  margin-right: 4px;
  margin-left: 12px;
}
.u0MQkZwigAb_cp::before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: inherit;
}
.bya4qnKPZkNqE_ {
  background-color: var(--ds-background-accent-blue-subtle, #1868db);
}
.vGG6WDE3ZqDb7D {
  margin-left: 6px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ppOptInBanner": `tRc78MH7h7eEaW`,
	"tacoMessage": `u83SxONudILTXX`,
	"tinyTacoTalkingPng": `_d5VkNpiqsvcw1`,
	"speechBubble": `u0MQkZwigAb_cp`,
	"textCta": `XZoNmzSh_1lpdU`,
	"dismiss": `bya4qnKPZkNqE_`,
	"magicWand": `vGG6WDE3ZqDb7D`
};
export default ___CSS_LOADER_EXPORT___;
