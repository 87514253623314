import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { trelloServerMicrosUrl } from '@trello/config';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { parseNetworkError } from '@trello/graphql-error-handling';
import type { SafeUrl } from '@trello/safe-urls';
import { sanitizeUrl } from '@trello/safe-urls';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import { safeFetch, safeTrelloFetch } from '../fetch';
import type {
  EnterpriseClaimableOrganizationsArgs,
  EnterpriseExportArgs,
  EnterpriseManagedMembersWithTokensArgs,
  EnterpriseMembershipsArgs,
  EnterpriseOrganizationsArgs,
  EnterpriseTransferrableDataArgs,
  MutationAssignMemberEnterpriseAdminArgs,
  MutationClaimOrganizationArgs,
  MutationCreateSeatAutomationExportArgs,
  MutationCreateSelfServeExpansionArgs,
  MutationDeactivateEnterpriseMemberArgs,
  MutationDeleteManagedMemberTokensArgs,
  MutationDismissCompletedWorkspaceBatchesArgs,
  MutationGrantEnterpriseLicenseArgs,
  MutationLinkEnterpriseWithAtlassianOrganizationArgs,
  MutationReactivateMemberArgs,
  MutationRemoveEnterpriseMemberArgs,
  MutationRunSeatAutomationArgs,
  MutationStartEnterpriseExportArgs,
  MutationUpdateDefaultWorkspaceArgs,
  MutationUpdateEnterpriseApiTokenCreationPermissionArgs,
  MutationUpdateEnterprisePrefsSeatAutomationArgs,
  MutationUpdateEnterprisePrefsSeatAutomationBlockedMembersArgs,
  QueryFetchSeatAutomationExportArgs,
  QueryFetchSeatAutomationPreviewArgs,
  QueryGetAssociatedWorkspacesForMemberArgs,
  QuerySeatAutomationBlocklistMembersArgs,
  QuerySeatAutomationHistoryArgs,
  QuerySeatAutomationNextRunDateArgs,
  QuerySelfServeExpansionEstimateArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import {
  getChildFieldNames,
  getChildNodes,
} from '../restResourceResolver/queryParsing';
import type { TrelloRestResolver } from '../types';

/**
 * Enterprise.organizations is queried as a custom resolver (as opposed to
 * utilizing restResourceResolver)for two reasons
 *
 * 1. At the time of this resolver's writing, the Enterprise API did not support the
 * "sortBy", "sortOrder", "count", or "startIndex" params for the Organizations nested
 * resource.
 *
 * 2. The API returns a list of Organizations, while the GQL type includes both
 * the list of Organizations, as well as the total count of Organizations (extracted
 * via an HTTP header)
 */
export const enterpriseOrganizationsResolver: TrelloRestResolver<
  EnterpriseOrganizationsArgs
> = async (enterprise, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { count, startIndex, query, activeSince, inactiveSince } = args ?? {};

  const searchParams = new URLSearchParams({
    ...(Number.isInteger(count) && { count: String(count) }),
    ...(Number.isInteger(startIndex) && { startIndex: String(startIndex) }),
    ...(query && { filter: `displayName co "${query}"` }),
    ...(activeSince && { activeSince }),
    ...(inactiveSince && { inactiveSince }),
  });

  const children = getChildNodes(rootNode);
  const organizationsSelection = children.find(
    (child) => child.name.value === 'organizations',
  );
  if (organizationsSelection) {
    searchParams.set(
      'fields',
      getChildFieldNames(organizationsSelection, ['memberships']).join(','),
    );
  }

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/claimedWorkspaces?${searchParams}`;

  const response = await safeTrelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.organizations',
      operationName: context.operationName,
    },
    deduplicate: context.deduplicate,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const organizations = await response.json();
  const apiQueryMetaHeader = response.headers.get('x-trello-api-query-meta');
  const apiQueryMeta = apiQueryMetaHeader
    ? JSON.parse(apiQueryMetaHeader)
    : null;
  const totalOrganizations = apiQueryMeta?.['totalResults'] ?? 0;
  const model = { organizations, totalOrganizations };

  return prepareDataForApolloCache(model, rootNode, 'Enterprise');
};

export const enterpriseClaimableOrganizationsResolver: TrelloRestResolver<
  EnterpriseClaimableOrganizationsArgs
> = async (enterprise, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;
  const { limit, cursor, name, activeSince, inactiveSince } = args;

  const urlParams = new URLSearchParams();
  urlParams.set('limit', (limit || 20).toString());
  urlParams.set('cursor', cursor || '');
  if (name) {
    urlParams.set('name', name);
  }
  if (activeSince && !inactiveSince) {
    urlParams.set('activeSince', activeSince);
  } else if (inactiveSince && !activeSince) {
    urlParams.set('inactiveSince', inactiveSince);
  }

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/claimableOrganizations?${urlParams}`;

  try {
    const response = await safeTrelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Enterprise.claimableOrganizations',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      const {
        organizations,
        claimableCount,
        cursor: nextCursor,
      } = await response.json();
      model = { organizations, count: claimableCount, cursor: nextCursor };
    } else {
      throw new Error(
        `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
      );
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Enterprise')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const transferrableDataForOrganizationResolver: TrelloRestResolver<
  EnterpriseTransferrableDataArgs
> = async (enterprise, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const urlParams = new URLSearchParams();
  urlParams.set('idOrganizations', args.idOrganizations.toString());

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/transferrable/bulk?${urlParams}`;

  const response = await safeTrelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.transferrableData',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  const model = await response.json();

  // The API should probably not be sending back status 200 for this, but alas
  if ('message' in model) {
    console.error(model);
    throw new Error(model.message);
  }

  return model
    ? prepareDataForApolloCache(model.organizations, rootNode, 'Enterprise')
    : model;
};

export const startEnterpriseExport: TrelloRestResolver<
  MutationStartEnterpriseExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.id,
    type: 'enterpriseId',
  }}/exports`;

  const response = await safeFetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      enterpriseAssociationType: args.enterpriseAssociationType,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const getEnterpriseExportResolver: TrelloRestResolver<
  EnterpriseExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprise/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}/exports/${{ value: args.idExport, type: 'otherId' }}`;

  const response = await safeTrelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.export',
      operationName: context.operationName,
    },
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }
  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const deactivateEnterpriseMember: TrelloRestResolver<
  MutationDeactivateEnterpriseMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/${{ value: idMember, type: 'memberId' }}/deactivated`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  return response;
};

export const assignMemberEnterpriseAdmin: TrelloRestResolver<
  MutationAssignMemberEnterpriseAdminArgs
> = async (obj, { idEnterprise, idMember, isAdmin }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/admins/${{ value: idMember, type: 'memberId' }}`;

  const response = await safeFetch(apiUrl, {
    method: isAdmin ? 'PUT' : 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const grantEnterpriseLicense: TrelloRestResolver<
  MutationGrantEnterpriseLicenseArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/${{ value: idMember, type: 'memberId' }}/licensed`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const reactivateMember: TrelloRestResolver<
  MutationReactivateMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'false');

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/${{ value: idMember, type: 'memberId' }}/deactivated`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const removeEnterpriseMember: TrelloRestResolver<
  MutationRemoveEnterpriseMemberArgs
> = async (obj, { idEnterprise, idMember }, context, info) => {
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/${{ value: idMember, type: 'memberId' }}`;

  const response = await safeFetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return response;
};

export const claimOrganization: TrelloRestResolver<
  MutationClaimOrganizationArgs
> = async (obj, { idEnterprise, idOrganizations, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/organizations/bulk`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      idOrganizations,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const declineOrganizations: TrelloRestResolver<
  MutationClaimOrganizationArgs
> = async (obj, { idEnterprise, idOrganizations, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/enterpriseJoinRequest/bulk`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      idOrganizations,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const linkEnterpriseWithAtlassianOrganization: TrelloRestResolver<
  MutationLinkEnterpriseWithAtlassianOrganizationArgs
> = async (
  obj,
  { idEnterprise, atlOrgId, enterpriseARI, linkToAtlassianOrgV2 },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const performFetch = async (url: SafeUrl, reqBody = {}) => {
    const response = await safeFetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify(reqBody),
    });
    if (!response.ok) {
      sendNetworkErrorEvent({
        url,
        response: await response.clone().text(),
        status: response.status,
        operationName: context.operationName,
      });
    }
    return response;
  };

  if (linkToAtlassianOrgV2) {
    const callbackUrl = `${trelloServerMicrosUrl}/1/atl/backfillProvisionerCallback/${idEnterprise}`;
    const reqBody = {
      callbackUrl,
      audience: 'trello-server',
    };
    const apiUrl = sanitizeUrl`/admin/private/api/admin/v1/orgs/${{
      value: atlOrgId,
      type: 'organizationId',
    }}/workspaces/${{
      value: enterpriseARI,
      type: 'workspaceId',
    }}/_link`;
    const gatewayUrl = getApiGatewayUrl(apiUrl as unknown as string);
    const response = await performFetch(
      gatewayUrl as unknown as SafeUrl,
      reqBody,
    );
    if (!response.ok) {
      throw await parseNetworkError(response);
    }
  }

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/linkWithAtlassianOrganization`;
  const response = await performFetch(apiUrl, {
    atlOrgId,
    ...getCsrfRequestPayload(),
  });
  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const dismissCompletedWorkspaceBatches: TrelloRestResolver<
  MutationDismissCompletedWorkspaceBatchesArgs
> = async (obj, { idEnterprise, idBatches }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/organizations/add-to-enterprise-batches/completed`;

  const response = await safeFetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      idsBatch: idBatches,
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const managedMembersWithTokensResolver: TrelloRestResolver<
  EnterpriseManagedMembersWithTokensArgs
> = async (enterprise, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const params = new URLSearchParams();
  if (args?.filter) {
    params.set('filter', args.filter);
  }

  const apiUrl = params.toString()
    ? sanitizeUrl`/1/enterprises/${{
        value: enterprise.id,
        type: 'enterpriseId',
      }}/members/tokens?${params}`
    : sanitizeUrl`/1/enterprises/${{
        value: enterprise.id,
        type: 'enterpriseId',
      }}/members/tokens`;

  try {
    const response = await safeTrelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Enterprise.managedMembersWithTokens',
        operationName: context.operationName,
      },
    });

    if (!response.ok) {
      const error = await response.text();
      throw new Error(error);
    }

    const model = await response.json();
    return model
      ? prepareDataForApolloCache(model, rootNode, 'Enterprise')
      : model;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteManagedMemberTokens: TrelloRestResolver<
  MutationDeleteManagedMemberTokensArgs
> = async (obj, { idEnterprise, idMember, filter }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/tokens/${{ value: idMember, type: 'memberId' }}`;

  const response = await safeFetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      filter,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const deleteAllManagedMemberTokens: TrelloRestResolver<
  MutationDeleteManagedMemberTokensArgs
> = async (obj, { idEnterprise, filter }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/tokens`;

  const response = await safeFetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      filter,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    const error = await response.text();
    console.error(error);
    throw new Error(error);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateEnterpriseApiTokenCreationPermission: TrelloRestResolver<
  MutationUpdateEnterpriseApiTokenCreationPermissionArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprise/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      'prefs/canIssueManagedConsentTokens': args.isAllowed,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode);
};

export const auditlogResolver: TrelloRestResolver<null> = async (
  enterprise,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprise/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/auditlog`;

  const response = await safeFetch(apiUrl, {
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const selfServeExpansionEstimateResolver: TrelloRestResolver<
  QuerySelfServeExpansionEstimateArgs
> = async (obj, args, context, info) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}/seat-expansion-estimated-price/${{
    value: args.seats,
    type: 'number',
  }}`;

  const response = await safeFetch(apiUrl, {
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      Authorization: `Bearer ${document.cookie}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const result = await response.json();
  return result;
};

export const createSelfServeExpansion: TrelloRestResolver<
  MutationCreateSelfServeExpansionArgs
> = async (obj, args, context) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}/seat-expansion`;

  const response = await safeFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-Trello-Client-Version': context.clientAwareness.version,
      Authorization: `Bearer ${document.cookie}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seats: args.seats,
      ...getCsrfRequestPayload(),
    }),
  });

  return { ok: response.ok };
};

export const defaultOrganizationResolver: TrelloRestResolver<null> = async (
  enterprise,
  args,
  context,
) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/organizations/default`;

  const response = await safeTrelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Enterprise.defaultOrganization',
      operationName: context.operationName,
    },
  });

  if (response.status === 404) {
    // The workspace hasn't been set yet - that's OK
    return null;
  }

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return response.json();
};

export const updateDefaultWorkspace: TrelloRestResolver<
  MutationUpdateDefaultWorkspaceArgs
> = async (obj, args, context) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}/organizations/default`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      idOrganization: args.idOrganization,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return response.json();
};

export const membershipsResolver: TrelloRestResolver<
  EnterpriseMembershipsArgs
> = async (enterprise, { after, filter }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  // The query endpoint does not allow values to be null, undefined or empty.
  // They must be removed from the request body object.
  const filterWithoutNulls = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, v]) => v !== null && v !== undefined && v !== '',
    ),
  );

  const searchParams = new URLSearchParams(
    filterWithoutNulls as Record<string, string>,
  );
  if (after) {
    searchParams.append('cursor', after);
  }

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: enterprise.id,
    type: 'enterpriseId',
  }}/members/query?${searchParams}`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return prepareDataForApolloCache(body, rootNode, 'Enterprise');
};

export const updateEnterprisePrefsSeatAutomation: TrelloRestResolver<
  MutationUpdateEnterprisePrefsSeatAutomationArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      [`prefs/seatAutomationSetting/${args.pref}`]: args.value,
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const enterprise = await response.json();
  return prepareDataForApolloCache(enterprise, rootNode);
};

export const updateEnterprisePrefsSeatAutomationBlockedMembers: TrelloRestResolver<
  MutationUpdateEnterprisePrefsSeatAutomationBlockedMembersArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: args.idEnterprise,
    type: 'enterpriseId',
  }}`;

  const response = await safeFetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      [`prefs/seatAutomationSetting/memberBlocklist`]: args.value,
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const enterprise = await response.json();
  return prepareDataForApolloCache(enterprise, rootNode);
};

export const getAssociatedWorkspacesForMemberResolver: TrelloRestResolver<
  QueryGetAssociatedWorkspacesForMemberArgs
> = async (obj, { idMember, idEnterprise }, context) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/members/${{
    value: idMember,
    type: 'memberId',
  }}/associatedOrganizations?fields=name,displayName,logoHash,memberships,url`;
  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const workspaces = await response.json();
  return workspaces;
};

export const seatAutomationHistoryResolver: TrelloRestResolver<
  QuerySeatAutomationHistoryArgs
> = async (obj, { idEnterprise }, context) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/seatautomations`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  const body = await response.json();
  return body;
};

export const seatAutomationPreviewResolver: TrelloRestResolver<
  QueryFetchSeatAutomationPreviewArgs
> = async (obj, { idEnterprise, activeDays, inactiveDays }, context, info) => {
  const params = new URLSearchParams();
  params.set('activeDays', activeDays.toString());
  params.set('inactiveDays', inactiveDays.toString());

  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/seatautomation/preview?${params}`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const seatAutomationBlocklistMembersResolver: TrelloRestResolver<
  QuerySeatAutomationBlocklistMembersArgs
> = async (obj, { idEnterprise }, context, info) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/seatautomation/memberBlocklistInfo`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return { blocklistMembers: body.members };
};

export const runSeatAutomation: TrelloRestResolver<
  MutationRunSeatAutomationArgs
> = async (obj, { idEnterprise }, context, info) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/seatautomation`;

  const response = await safeFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return body;
};

export const createSeatAutomationExport: TrelloRestResolver<
  MutationCreateSeatAutomationExportArgs
> = async (obj, { idEnterprise, idSeatAutomation }, context) => {
  const apiUrl = sanitizeUrl`/1/enterprises/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/exports`;

  const response = await safeFetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      Accept: 'application/json',
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      seatAutomationId: idSeatAutomation,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const fetchSeatAutomationExportResolver: TrelloRestResolver<
  QueryFetchSeatAutomationExportArgs
> = async (obj, { idEnterprise, idExport }, context) => {
  const apiUrl = sanitizeUrl`/1/enterprise/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/exports/${{ value: idExport, type: 'otherId' }}`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  const body = await response.json();
  return body;
};

export const seatAutomationNextRunDateResolver: TrelloRestResolver<
  QuerySeatAutomationNextRunDateArgs
> = async (_, { idEnterprise }, context) => {
  const apiUrl = sanitizeUrl`/1/enterprise/${{
    value: idEnterprise,
    type: 'enterpriseId',
  }}/seatautomation/next-run-date`;

  const response = await safeTrelloFetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();
  return body;
};
