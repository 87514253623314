import { useMemo } from 'react';

import { useOneTimeMessagesDismissed } from '@trello/business-logic-react/member';
import { useFeatureGate } from '@trello/feature-gate-client';
import { RouteId, useRouteId } from '@trello/router';

export const PP_OPT_IN_BANNER_MESSAGE = 'pp-opt-in-banner';

/**
 * Custom hook to determine if the Personal Productivity Opt-In Banner should be rendered.
 *
 * This hook checks if the banner message has been dismissed, if the current route is a board or card route,
 * and if the feature gate for the banner is enabled.
 *
 * @returns {Object} An object containing a boolean `wouldRender`
 * (common interface in Banners.tsx)
 * indicating if the banner should be rendered.
 */
export const usePPOptInBanner = () => {
  // hooks
  const { isOneTimeMessageDismissed } = useOneTimeMessagesDismissed();

  const isMessageDismissed = isOneTimeMessageDismissed(
    PP_OPT_IN_BANNER_MESSAGE,
  );

  const routeId = useRouteId();
  const isBoardOrCardRoute = (
    [RouteId.BOARD, RouteId.CARD] as string[]
  ).includes(routeId);

  const { value: isGateEnabled } = useFeatureGate('trello_pp_opt_in_banner');

  // memoized state
  const result = useMemo(
    () => ({
      wouldRender: !isMessageDismissed && isBoardOrCardRoute && isGateEnabled,
    }),
    [isBoardOrCardRoute, isGateEnabled, isMessageDismissed],
  );
  return result;
};
