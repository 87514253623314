import { useCallback, useEffect, type FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import MagicWandIcon from '@atlaskit/icon/core/magic-wand';
import { Analytics } from '@trello/atlassian-analytics';
import { useOneTimeMessagesDismissed } from '@trello/business-logic-react/member';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { Button } from '@trello/nachos/button';
import { showFlag } from '@trello/nachos/experimental-flags';

import { useAssignPersonalProductivityCohortMutation } from '../AssignPersonalProductivityCohortMutation.generated';
import { PP_OPT_IN_BANNER_MESSAGE } from './usePPOptInBanner';

import styles from './PPOptInBanner.less';

export const PPOptInBanner: FunctionComponent = () => {
  const { dismissOneTimeMessage } = useOneTimeMessagesDismissed();

  const [assignPersonalProductivityCohort] =
    useAssignPersonalProductivityCohortMutation({});

  const onOptIn = useCallback(async () => {
    Analytics.sendClickedButtonEvent({
      buttonName: 'enableBetaPPOptInBannerButton',
      source: 'ppOptInBanner',
    });

    try {
      const assignCohortRes = await assignPersonalProductivityCohort({
        variables: { assignmentValue: 'opted_in' },
      });

      if (assignCohortRes.errors) {
        throw assignCohortRes.errors;
      }

      const cohorts =
        assignCohortRes.data?.assignPersonalProductivityCohort?.cohorts;

      if (
        !cohorts?.find(
          (cohort) =>
            cohort?.name === 'userCohortPersonalProductivity' &&
            cohort?.value === 'opted_in',
        )
      ) {
        throw new Error(
          `expected cohort name and value missing, cohorts: ${JSON.stringify(cohorts)}`,
        );
      }
    } catch (error) {
      showFlag({
        id: 'ppOptInBannerError',
        title: 'Error opting in to Personal Productivity.',
        appearance: 'error',
      });
    }

    dismissOneTimeMessage(PP_OPT_IN_BANNER_MESSAGE);
  }, [assignPersonalProductivityCohort, dismissOneTimeMessage]);

  const onDismiss = useCallback(() => {
    dismissOneTimeMessage(PP_OPT_IN_BANNER_MESSAGE);
    Analytics.sendClickedButtonEvent({
      buttonName: 'dismissPPOptInBannerButton',
      source: 'ppOptInBanner',
    });
  }, [dismissOneTimeMessage]);

  const onLearnMoreClick = useCallback(() => {
    Analytics.sendClickedLinkEvent({
      linkName: 'learnMorePPOptInBannerLink',
      source: 'ppOptInBanner',
    });
  }, []);

  useEffect(() => {
    Analytics.sendViewedBannerEvent({
      bannerName: 'ppOptInBanner',
      source: getScreenFromUrl(),
    });
  }, []);

  return (
    <>
      <div className={styles.ppOptInBanner}>
        <div className={styles.tacoMessage}>
          <div className={styles.tinyTacoTalkingPng} />

          <div className={styles.speechBubble}>
            <FormattedMessage
              id="templates.pp_launch_and_learn.banner.switch-message"
              defaultMessage="Try the beta experience and help us build the next-gen Trello."
              description="banner main message"
            />
            <a
              className={styles.textCta}
              href="https://support.atlassian.com/trello/docs/new-trello-support/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={onLearnMoreClick}
            >
              <FormattedMessage
                id="templates.pp_launch_and_learn.banner.switch-cta"
                defaultMessage="Find out more"
                description="banner info cta"
              />
            </a>
          </div>
        </div>

        <Button className={styles.dismiss} onClick={onDismiss}>
          <FormattedMessage
            id="templates.pp_launch_and_learn.banner.dismiss-button"
            defaultMessage="Dismiss"
            description="dismiss button"
          />
        </Button>

        <Button onClick={onOptIn}>
          <FormattedMessage
            id="templates.pp_launch_and_learn.banner.switch-button"
            defaultMessage="Enable Trello beta"
            description="switch button"
          />
          <div className={styles.magicWand}>
            <MagicWandIcon label="" />
          </div>
        </Button>
      </div>
    </>
  );
};
