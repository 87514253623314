// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s_r1D9OhMvhi3F {
  margin-left: 8px;
}
.QEoKH2sHHAt2Z0 {
  list-style: none;
}
.EZj9VI4TUpMILw {
  margin-left: 8px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevron": `s_r1D9OhMvhi3F`,
	"menu": `QEoKH2sHHAt2Z0`,
	"title": `EZj9VI4TUpMILw`
};
export default ___CSS_LOADER_EXPORT___;
